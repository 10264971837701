<script lang="ts">
import { getCmsImage } from '@/composables/useCms';

interface Cat {
  title: string;
  uri: string;
  image: { url: string }[];
}

export default defineComponent({
  props: {
    heading: {
      type: String,
      default: null,
    },
    categories: {
      type: Array as PropType<Cat[]>,
      default: () => [],
    },
  },
  setup() {
    const localePath = useLocalePath();
    return {
      localePath,
      getCmsImage,
    };
  },
});
</script>

<template>
  <v-container>
    <h2 class="text-2xl mb-4 font-bold text-gray-900">
      {{ heading }}
    </h2>
    <div class="sm:grid-cols-2 sm:max-h-[550px] sm:grid-rows-2 sm:gap-x-6 max-h-fit mt-6 grid grid-cols-1 gap-y-6 lg:gap-8">
      <NuxtLink
        v-for="category in categories"
        :key="category.uri"
        :to="localePath('/' + category.uri)"
        class="relative group aspect-h-1 first:row-span-2 aspect-w-2 overflow-hidden h-full"
      >
        <div
          aria-hidden="true"
          class="absolute inset-0 bg-gradient-to-b from-transparent opacity-50 to-black group-hover:bg-none"
        />
        <NuxtImg 
          :alt="category.title" 
          :src="getCmsImage(category.image)"
          format="webp"
          loading="lazy"
          class="object-cover w-full object-center h-full" 
        />
        <div class="flex items-end p-6 absolute inset-0">
          <div>
            <h3 class="font-semibold text-white text-2xl group-hover:hidden">
              <span class="absolute inset-0" />
              {{ category.title }}
              <div class="text-gray-300" v-html="category.description" />
            </h3>
          </div>
        </div>
      </NuxtLink>
    </div>
  </v-container>
</template>
